/* Write your custom CSS here */
.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

::-webkit-scrollbar {
  height: 1px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}

::-webkit-scrollbar-track {
  background: #262635;

}

::-webkit-scrollbar-thumb {
  background: #262635;
}

.page-sidebar .accordion-menu::-webkit-scrollbar {
  height: 0px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 4px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}

.collapse::-webkit-scrollbar {
  height: 0px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 2px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #ffff !important;
}

.followDiv::-webkit-scrollbar {
  height: 0px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 3px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}

.image {
  opacity: 1;
  display: block;
  width: 38%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.table_image {
  box-shadow: rgba(105, 103, 103, 0) 0px 5px 15px 0px;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 10px;
  float: left;
  object-fit: cover;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.container:hover .image {
  opacity: 0.6;
  cursor: grab;
}

.container:hover .middle {
  opacity: 1;
}

.page-sidebar .accordion-menu > li > span {
  display: block;
  color: #9a9cab;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  line-height: 45px;
  padding: 0 15px;
  text-decoration: none;
  cursor: pointer;
}

.page-sidebar .accordion-menu > li > span > svg {
  width: 21px;
  height: 21px;
  line-height: 40px;
  text-align: center;
  vertical-align: text-top;
  color: #9a9cab;
  margin-right: 15px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.sidenav__icon {
  margin-right: 15px;
}

.card-overflow {
  overflow-x: scroll;
}

.card-overflow::-webkit-scrollbar {
  height: 1px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 3px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}

.card-overflow::-webkit-scrollbar-track {
  background: #fff;
}

.card-overflow::-webkit-scrollbar-thumb {
  background: #1f1f2b;
}

.back__style {
  width: "100%";
  height: "100%";
  background-size: "cover";
  background-attachment: "fixed";
  margin: 0;
  padding: 0;
  background-image: url("../images/login.png");
}

.icon_margin {
  margin-left: 5px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 58px;
  height: 32px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #86c1ed;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ada6f2;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ada6f2;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.searchBar:focus {
  background-color: #1f1f2b;
  box-shadow: none;
}

.MuiTypography-colorInherit {
  color: #91a3b0 !important;
}

.MuiSelect-select {
  color: #91a3b0 !important;
}

.MuiSelect-icon {
  color: #91a3b0 !important;
}

.MuiIconButton-root.Mui-disabled {
  color: #9a9cab !important;
}

.MuiIconButton-root {
  color: #000 !important;
}

.float__right {
  float: right;
}

.MuiPaper-root {
  background-color: #fff !important;
}

.text-gray {
  color: #91a3b0 !important;
}

.text-red {
  color: #de1738;
}

.collapse-margin {
  margin-left: -7px;
  /* overflow-x: scroll; */
}

.pointer-cursor {
  cursor: pointer;
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: aliceblue;
  color: #fff;
}

.post-padding {
  padding: 6px 15px !important;
  margin-bottom: 0;
}

.commentImg {
  margin-top: 13px;
}

.shadow {
  box-shadow: rgb(105 103 103 / 50%) 0px 5px 15px 0px;
  border: 2px solid #e8538f;
}

.comment-delete {
  margin-left: 10px;
}

.form-check-input:focus,
.form-control:focus {
  box-shadow: none;
}

.rows > * {
  max-height: 500px;
}

.pie > * {
  max-height: 350px;
}

.swal-title {
  color: rgba(255, 255, 255, 0.65) !important;
  font-weight: 500 !important;
}

.swal-text {
  color: rgba(255, 255, 255, 0.64) !important;
}

.swal-modal {
  background-color: #161622 !important;
}

.swal-icon--success__hide-corners,
.swal-icon--success:after,
.swal-icon--success:before {
  background-color: transparent;
}

.MuiSelect-select:not([multiple]) option,
.MuiSelect-select:not([multiple]) optgroup {
  background-color: #161622 !important;
}

.chip,
.highlightOption,
.multiSelectContainer li:hover {
  background: #e8538f !important;
}

.page-sidebar .accordion-menu {
  overflow-x: scroll !important;
}

.danger {
  background-color: #f5365c;
}

.success {
  background-color: #146356;
}

.stats-card .stats-icon.change-pink {
  color: #fff;
  background-color: #fc9494;
  box-shadow: 0 3px 18px -8px #fc9494;
}

.border-right-radius {
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem;
}

.pagination {
  /* float: right; */
}

.page-link {
  border-radius: 50% !important;
  padding: 5px 13px;
}

.page-item {
  padding: 5px;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff !important;
  background-color: #e8538f;
  border-color: #e8538f;
}

.page-link:focus {
  box-shadow: none;
}

.dropdown {
  max-height: 30px;
  margin-top: 15px;
  padding: 3px 5px;
  font-size: 12px;
  max-width: 80px;
}

#datePicker {
  position: absolute;
  z-index: 1;
}

.table {
  position: relative;
}

.rdrDefinedRangesWrapper {
  background: #fff !important;
  color: #9a9cab;
  border-right: solid 1px #9a9cab !important;
}

.rdrStaticRange {
  background: #fff !important;
  color: #9a9cab;
  border-bottom: 1px solid #9a9cab !important;
}

.rdrCalendarWrapper,
.rdrInputRangeInput {
  background: #fff !important;
}

.rdrMonthAndYearPickers select,
.rdrDayPassive .rdrDayNumber span {
  color: #9a9cab !important;
}

.rdrDayNumber span {
  color: #fff !important;
}

.rdrDateRangePickerWrapper {
  border: solid 1px #9a9cab !important;
}

.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
  background: #1f1f2b !important;
}

.rdrNextPrevButton,
.rdrDateDisplayItem,
.rdrDateDisplayItem input {
  background: #fff !important;
  color: #000 !important;
}

.rdrDateDisplayItemActive {
  border-color: #fff !important;
}

.rdrDateDisplayWrapper {
  background-color: #1f1f2b !important;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  color: #ada6f2 !important;
}

.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrStartEdge,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrStartEdge,
.rdrStartEdge,
.rdrInRange,
.rdrEndEdge,
.rdrDayToday .rdrDayNumber span:after {
  background: #ada6f2 !important;
}

.rdrInputRangeInput {
  border: solid 1px #ada6f2 !important;
  color: #fff !important;
}

.rdrInputRangeInput:focus,
.rdrInputRangeInput:hover {
  border-color: #ada6f2;
  outline: 0;
  color: #fff !important;
}

.rdrDayEndOfMonth .rdrDayInPreview,
.rdrDayEndOfMonth .rdrDayStartPreview,
.rdrDayEndOfWeek .rdrDayInPreview,
.rdrDayEndOfWeek .rdrDayStartPreview {
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  border-right-width: 1px;
  right: 0px;
  border-color: #ada6f2 !important;
}

.rdrDayStartOfMonth .rdrDayInPreview,
.rdrDayStartOfMonth .rdrDayEndPreview,
.rdrDayStartOfWeek .rdrDayInPreview,
.rdrDayStartOfWeek .rdrDayEndPreview {
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  border-left-width: 1px;
  left: 0px;
  border-color: #ada6f2 !important;
}

.rdrDayEndPreview,
.rdrDayStartPreview {
  border-color: #ada6f2 !important;
}

.rdrDayInPreview {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: #ada6f2 !important;
}

.rdrYearPicker option,
.rdrMonthPicker option {
  background: #262635 !important;
}

.rdrYearPicker select::-webkit-scrollbar {
  height: 0px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 4px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}

.rdrYearPicker select::-webkit-scrollbar-track {
  background: #fff;
}

.rdrYearPicker select::-webkit-scrollbar-thumb {
  background: #262635;
}

.rdrDateDisplayItemActive {
  border: 3px solid #959595 !important;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.styles-module_Editext__input__2-M50,
.styles-module_Editext__button__sxYQX {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background-color: #fff !important;
  color: #fff !important;
  border-color: #fff !important;
}

.hiddenRow {
  padding: 0 !important;
}

.profile-header .profile-img img {
  object-fit: cover;
}

.makeStyles-navLink-1.active {
  font-size: 15px !important;
}

.accordion-toggle {
  border-top-width: 3px;
}

.daterangepicker,
.daterangepicker .calendar-table,
.daterangepicker td.off {
  color: #9a9cab;
  background-color: #1f1f2b !important;
  border: 1px solid #1f1f2b !important;
}

.daterangepicker td.active,
.daterangepicker td.in-range,
.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: #fff !important;
  border-color: transparent;
  color: #9a9cab !important;
}

.applyBtn {
  background-color: #ada6f2 !important;
}

.cancelBtn {
  color: #9a9cab !important;
}

.calendar-table .prev span,
.daterangepicker .calendar-table .next span {
  color: #1f1f2b !important;
  border: solid #9a9cab !important;

  border-width: 0 2px 2px 0 !important;
  border-radius: 0 !important;
  display: inline-block !important;
  padding: 3px !important;
}

.daterangepicker.opensright:before,
.daterangepicker.opensright:after {
  left: 50% !important;
}

.daterangepicker:after,
.daterangepicker:before {
  border-bottom: 6px solid #1f1f2b !important;
}

.disabledBtn {
  background-color: #1f1f2b !important;
  color: #9a9cab !important;
}
@media (max-width: 768px) {
  body {
    font-size: 11px !important;
  }
  .page-sidebar .accordion-menu > li > a {
    line-height: 33px !important;
  }
  .makeStyles-navLink-1.active {
    font-size: 12px !important;
  }
  .btn {
    padding: 6px 14px !important;
  }
  body.sidebar-hidden .page-content {
    margin-left: -45px;
  }
  .page-sidebar {
    width: 190px !important;
  }
  .page-sidebar .logo {
    font-size: 25px;
  }
  .sidenav__icon > svg {
    height: 20px;
    width: 20px;
  }
}

.text-dark {
  color: #fff !important;
}
.income-detail {
  border-radius: 10px !important;
  padding: 10px !important;
  background-color: #fff4e8 !important;
}

.inner-income {
  border-radius: 10px !important;
  padding: 10px !important;
  background-color: #fffaf4 !important;
}

.agency-detail {
  background-color: rgb(36, 19, 48) !important;
  padding: 10px !important;
  border-radius: 10px !important;
}

.agency-invitation {
  border-radius: 10px !important;
}

.bg-gray {
  background-color: #f1f1f1 !important;
}
.activeAgency {
  border-bottom: 3px solid #000 !important;
}

.react-datepicker__input-container > input {
  width: 70px;
  text-align: center !important ;
  border-radius: 10px !important;
  border: 1px solid #000 !important;
}

.react-datepicker-popper {
  position: absolute !important;
  left: 0px !important;
  top: -20px !important;
  transform: translate(189.5px, 102.5px) !important;
  will-change: transform !important;
}
.cursor-none {
  cursor: none !important;
}

.host-detail {
  background-color: #fff2ce !important;
  border-radius: 15px;
}

.room-btn {
  border-radius: 35px !important;
  padding: 5px !important;
  border: 1px solid lightgray !important;
  color: rgb(56, 56, 56) !important;
  background-color: #f7f8fb !important;
}

.active-btn {
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 35px !important;
  font-weight: 500 !important;
  padding: 5px !important;
  border: 1px solid #fff !important;
  box-shadow: 0px 0px 2px #2d2d2d !important ;
}

hr {
  width: 20px !important;
  background-color: black !important;
  height: 2px !important;
  color: #000 !important;
  border-color: #000 !important;
}
.text-bfg {
  color: #1d1d1d !important;
}

.claimbutton{
  border: none;
  outline: none;
  border-radius: 20px;
  width : 200px;
  padding : 10px;
  color : "#FFF";
  font-weight: 700;
  font-size: 14px;
}

/* Task Css */

/* General Styles */
body {
  font-family: Lato, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #2c3e50;
  color: white;
}

.container {
  max-width: 425px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

header h1 {
  font-size: 1.5rem;
  font-weight: bold;
}

header h2 {
  font-size: 2rem;
  margin: 10px 0;
}

.summary p {
  font-size: 1rem;
  margin: 5px 0;
}

.summary span {
  font-weight: bold;
}

.timer {
  margin: 20px 0;
}

.reset-btn {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.timer-display {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px !important;
}

.timer-display div {
  background-color: rgba(30, 108, 147, 1);
  padding: 10px;
  color: white;
  border-radius: 5px;
  font-size: 1rem;
}

.timer-display span {
  display: block;
  font-size: 0.8rem;
  margin-top: 5px;
}

.task-details h3 {
  font-size: 2.0rem;
  margin: 20px 0 10px;
  text-align: center;
}

.task-details .reward {
  font-size: 1.7rem;
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
}

.task-details p {
  font-size: 1rem;
  margin: 5px 0;
}

.task-details span {
  font-weight: bold;
}

.total-remaining-minute{
  padding: 22px 0px;
}

/* Responsive Styles */
@media (max-width: 425px) {
  .container {
    padding: 15px 15px 0px 15px;
  }

  header p {
    font-size: 4.0rem;
    font-weight: normal;
    color: #FFF;
  }

  .summary{
  text-align: left;
  font-size: 11px;
  padding-bottom: 40px;
  }

  .bg-image {
    background-image: url("../images/image.png");
    background-color: rgba(34, 110, 147, 1) !important;
    background-size: cover; /* or contain, depending on your preference */
    background-position: center center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents repetition */
  }
  
  /* Responsive adjustments for different screen sizes */
 .claim-button{
  width: 100%;
  border: none;
  outline: none;
  font-size: 15px;
  color: white;
  background-color: rgba(30, 108, 147, 1);
  border-radius: 5px;
  padding: 5px;
 }

 .button{
  padding: 0px 20px 0px 20px;
 }

  .summary p {
    color:  #FFF;
  }

  .timer-display div {
    font-size: 0.9rem;
  }
}

@media (max-width: 375px) {
  header h2 {
    font-size: 1.6rem;
  }

  .summary p {
    font-size: 1rem;
  }

  .timer-display div {
    font-size: 0.8rem;
  }
}

@media (max-width: 320px) {
  header h2 {
    font-size: 1.4rem;
  }

  .summary p {
    font-size: 1.0rem;
  }

  .timer-display div {
    font-size: 0.7rem;
  }

  .task-details h3 {
    font-size: 1.2rem;
  }

  .task-details p {
    font-size: 0.9rem;
  }
}


